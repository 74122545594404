import {useEffect} from 'react';
import {Object3DTypedArray} from 'webgl/types/Object3DTypedArray';
import {productSheetStore} from 'webgl/stores';
import {AssetEntity} from 'webgl/entities/AssetEntity';

/**
 * Get all SortedObjects from product assetEntity and regroup them in a unique named array
 * to push them into productSheetStore.sortedObjects.
 */
export const useUpdateProductSheetSortedObjects = (assetEntity?: AssetEntity) => {
  const setSortedObjects = productSheetStore(state => state.setSortedObjects);
  useEffect(() => {
    const _sortedObjects: Object3DTypedArray = assetEntity?.sortedObjects || {};

    console.log('ProductSheetViewer sortedObjects', _sortedObjects); // TODO REMOVE DEBUG
    setSortedObjects(_sortedObjects);
  }, [assetEntity, setSortedObjects]);
};