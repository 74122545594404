import {useEffect} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {cameraStore, productSheetStore} from 'webgl/stores';
import {Object3D, PerspectiveCamera} from 'three';
import {AssetEntity} from 'webgl/entities/AssetEntity';

export const useUpdateProductSheetVirtualCameras = (assetEntity?: AssetEntity): void => {
  const {setVirtualCameras} = cameraStore(state => ({
    setVirtualCameras: state.setVirtualCameras
  }));
  const setProductSheet3dReadiness = productSheetStore(state => state.setProductSheet3dReadiness);

  useEffect(() => {
    // Virtual Cameras
    const vCams: PerspectiveCamera[] = [];
    if (assetEntity) {
      const _cams: PerspectiveCamera[] = [];
      assetEntity.sortedObjects['camera_virtual']?.forEach(cam => _cams.push(cam as PerspectiveCamera));

      // assetEntity.scene.traverse(obj => {
      //   if (obj.userData.tags?.type === 'camera_virtual') {
      //     _cams.push(obj as PerspectiveCamera);
      //   }
      // });
      setCameraTargets(_cams, assetEntity.scene);
      vCams.push(..._cams);
      // } // TODO COMMENTED TEST

      // const productSheetCamera = new PerspectiveCamera(50);
      // const productSheetCameraTarget = new Object3D();
      // productSheetCamera.name = 'PRODUCT SHEET CAMERA';
      // productSheetCamera.fov = 50;
      // productSheetCamera.position.set(0, 10, 0);
      // productSheetCamera.userData = {
      //   name: productSheetCamera.name,
      //   tags: {
      //     type: 'camera_virtual',
      //     activeStates: 'dynamicStates.default',
      //     cameraTarget: productSheetCameraTarget, // center
      //     orbitControls: true,
      //     enableRotate: true,
      //     enablePan: true,
      //     enableZoom: true,
      //     groundThreshold: undefined,
      //     lerpDuration: '1'
      //   }
      // }; // TODO hard coded, find a better alternative
      // productSheetCamera.lookAt(productSheetCameraTarget.position);
      // vCams.push(productSheetCamera);
      // tLogStyled('[useUpdateLineVirtualCameras] productSheetCamera', logHelper.debug, productSheetCamera);

      setVirtualCameras(vCams);
      tLogStyled('[useUpdateProductSheetVirtualCameras] Processing virtual cameras', logHelper.subdued, vCams);

      setProductSheet3dReadiness(true);
    } // TODO ADDED TEST

  }, [assetEntity, setProductSheet3dReadiness, setVirtualCameras]);
};

const setCameraTargets = (cameras: Object3D[], scene: Object3D): Object3D[] => {
  // If cameraTarget is set find this object by name
  // If not set then try to find object with the name Camera.name + "Target"

  // TODO PROBLEM when loading GLTF, sometimes Cameras are renamed with unique names (CameraName => CameraName_1)
  //  but Targets are not renamed! ==> USING `userData.name` instead of `obj.name`
  cameras.forEach(cam => {
    const targetName = [cam.userData.tags?.cameraTarget, cam.userData.name + '.Target'];
    scene.traverse(obj => {
      if (targetName.includes(obj.userData.name)) {
        cam.userData.tags.cameraTarget = obj; // replace target name by its object
      }
    });

  });

  return cameras;
};