import React from 'react';
import {useScaleWithScreenSize, useSizeClass} from 'hooks';

import './App.scss';
import Main from './Main/Main';
import 'react-toastify/dist/ReactToastify.css';
import {sizeClassStore} from 'stores';
import {getReferenceResolution} from 'stores/sizeClassStore';
import DebugLinks from 'components/Debug/DebugLinks';
import DebugOverlay from 'components/Debug/DebugOverlay';
import {useLocation} from 'react-use';

const App: React.FC = () => {
  const appRef = useSizeClass();
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const portrait = sizeClassStore(state => state.portrait);
  useScaleWithScreenSize(
    getReferenceResolution(sizeClass, portrait),
    0.2,
    8,
    16
  );

  const location = useLocation();

  return (
    <div ref={appRef} className={`app ${sizeClass}`}>
      {/*<Header/>*/}
      {location.search?.includes('debug') && // TODO REMOVE DEBUG
        <>
        <DebugLinks/>
        <DebugOverlay/>
      </>
      }
      <Main/>
      {/*<ToastContainer />*/}
    </div>
  );
};

export default App;
