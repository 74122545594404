import React from 'react';
import {sizeClassStore} from 'stores';
import {FSMStates} from 'webgl/types/FSMStates';
import {FSMStore} from 'webgl/stores';

import {ReactComponent as LogoSvg} from 'assets/images/skaleone_logo.svg';
import LogoPng from 'assets/images/LOGO_GM_B.png';

import './Logo.scss';

const Logo: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);

  return (
    <div className={`product-sheet-logo ${sizeClass}`}>
      {/*<LogoSvg className={'product-sheet-logo-svg'}/>*/}
      <img className={'product-sheet-logo-png'} src={LogoPng} alt={'logo'}/> {/* GMVirtuel */}
    </div>
  );
};

export default Logo;