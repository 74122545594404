import {Asset} from 'interfaces';
import {logHelper, tLogStyled} from 'utils/Logger';

// TODO FAKE DATE

const assets_FAKE: Asset[] = [
  {key: 'satellite', id:'YHv4rfx71fz2j9CBTi9Xu5E8', url: './assets/glb/satellite_skale_06_gmvirtuel.glb', fileSize: 2_236_544},
];

export const getAssetList__FAKE = async (): Promise<Asset[]> => {
  tLogStyled('[getAssetList__FAKE()] using fake data : assets_FAKE', logHelper.warning);

  return new Promise<Asset[]>((resolve) => {
    setTimeout(() => resolve(assets_FAKE), 0);
  });
};