import create from 'zustand';
import {Asset} from 'interfaces';

type AssetsState<T> = {
  assets: T[];
  setAssets: (value: T[]) => T[];
  getAssetByKey: (key?: string) => T | undefined;
};

const assetStore = create<AssetsState<Asset>>((set, get) => ({
  assets: [],
  setAssets: (value) => {
    set({assets: value});
    return value;
  },
  getAssetByKey: (key) => get().assets.find(asset => asset.key === key)
}));

export {assetStore};