import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useGetCurrentStateIndex} from 'webgl/hooks';
import {sizeClassStore} from 'stores';

import './SpacePlanetBackground.scss';

// import {ReactComponent as Close} from 'assets/images/background_close.svg';
import {ReactComponent as Background} from 'assets/images/background_faraway.svg';
import {FSMStore, productSheetStore} from "../../../webgl/stores";

const SpacePlanetBackground: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);

  const [farAway, setFarAway] = useState<boolean>(true);
  const {currentIndex, currentFSMState} = useGetCurrentStateIndex();
  const productSheetSubStatesArray = productSheetStore(state => state.productSheetSubStatesArray);
  const setFSMState = FSMStore(state => state.setFSMState);

  const goNextState = useCallback(() => {
    const nextState = productSheetSubStatesArray[currentIndex + 1];
    if (nextState !== undefined) setFSMState(nextState.value);
  }, [currentIndex, productSheetSubStatesArray, setFSMState]);


  // close if current state >= state1
  useEffect(() => setFarAway(
    currentIndex < 1 &&
    currentFSMState !== 'moreInformation' &&
    currentFSMState !== 'contact'
  ), [currentFSMState, currentIndex, setFarAway]);

  return (
    <div className={`background ${sizeClass}`} onClick={() => {
      goNextState();
    }}>
      <Background className={`${farAway ? '' : 'close'}`}/>
    </div>
  );
};

export default SpacePlanetBackground;