import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import {initReactI18next} from "react-i18next";
import {BASE_URL} from "../../services/api/httpClient";
import {URLs} from "../urls";

i18next.use(initReactI18next)
    .use(ChainedBackend).init({
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en'],
    ns: ['translation'],
    defaultNS: 'translation',
    backend: {
        backends: [
            HttpBackend
        ],
        backendOptions: [
            {
                loadPath:
                    `${BASE_URL}${URLs.API.TRANSLATIONS}?format=i18n&type=INTERFACE&lang=EN`
            }
        ]
    }
})

export default i18next;