import {CameraOffset} from 'webgl/types/CameraOffset';
// import {sizeClassStore} from 'stores/sizeClassStore';

const _portrait = window.screen.height > window.screen.width;

const _cameraOffsetLandscape = new CameraOffset({x: -350, y: 50, zoomFactor: 1});
const _cameraOffsetPortrait = new CameraOffset({x: 0, y: -100, zoomFactor: 1.4});

const _cameraOffsetDetailsLandscape = new CameraOffset({x: -350, y: 50, zoomFactor: 1});
const _cameraOffsetDetailsPortrait = new CameraOffset({x: 0, y: 100, zoomFactor: 1.2});

// const _screensaverCameraOffsetPortrait = new CameraOffset({x: 0, y: 300, zoomFactor: 1.25});
// const _demoCameraOffsetPortrait = new CameraOffset({x: 0, y: 200, zoomFactor: 1});
// const _overlayCameraOffsetPortrait = new CameraOffset({x: 0, y: 425, zoomFactor: .66});
// const _screensaverCameraOffsetLandscape = new CameraOffset({x: 0, y: 100, zoomFactor: 1.25});
// const _demoCameraOffsetLandscape = new CameraOffset({x: 350, y: 50, zoomFactor: 1});
// const _overlayCameraOffsetLandscape = new CameraOffset({x: 350, y: 300, zoomFactor: .75});

export const DefaultValues = {

  renderPauseDelay: 30_000, // delay in ms, 0=never, 5*60e3

  // Camera Controls
  lerpDuration: 0,
  orbitControlsEnabled: false,
  enableDamping: true,

  enableRotate: false,
  enablePan: false, // TODO PROD = false
  enableZoom: false,

  minAzimuthAngle: -Infinity,
  maxAzimuthAngle: Infinity,
  minPolarAngle: 0,
  maxPolarAngle: Math.PI / 2,
  minDistance: .25,
  maxDistance: 5,

  groundThreshold: null, // default null = no effect

  autoRotate: true, //false, // TODO CHANGE TO FALSE
  autoRotateSpeed: .25,

  PreventAnimationInterpolation: false, // animation is jerky but prevents glitch

  cameraOffset: _portrait ?? true ? _cameraOffsetPortrait : _cameraOffsetLandscape,
  detailsCameraOffset: _portrait ?? true ? _cameraOffsetDetailsPortrait : _cameraOffsetDetailsLandscape,
  // demoCameraOffset: _portrait ?? true ? _demoCameraOffsetPortrait : _demoCameraOffsetLandscape,
  // overlayCameraOffset: _portrait ?? true ? _overlayCameraOffsetPortrait : _overlayCameraOffsetLandscape,
};

// pseudo responsivity...
// sizeClassStore.subscribe(
//   state => state.portrait,
//   (value) => {
//     DefaultValues.screensaverCameraOffset = value ?? true ? _screensaverCameraOffsetPortrait : _screensaverCameraOffsetLandscape;
//     DefaultValues.demoCameraOffset = value ?? true ? _demoCameraOffsetPortrait : _demoCameraOffsetLandscape;
//     DefaultValues.overlayCameraOffset = value ?? true ? _overlayCameraOffsetPortrait : _overlayCameraOffsetLandscape;
//   },
//   {fireImmediately: true}
// );