import React from 'react';
import {getAssetList__FAKE} from 'services/getAssetList__FAKE';
import {updateAssetList} from 'webgl/services/webglService';
import WebGL from 'webgl/components/WebGL';
import ProductSheetViewer from 'components/Main/ProductSheet/ProductSheetViewer';
import Logo from 'components/Main/ProductSheet/Logo';
import Contact from 'components/Main/ProductSheet/Contact';

import './Main.scss';

// PRELOAD ASSETS
getAssetList__FAKE() // TODO implement real method
  .then(updateAssetList); // will trigger glb preloading

const Main: React.FC = () => {


  return (
    <div className={`main flex flex-center-horizontal`}>
      <ProductSheetViewer productSheetId={'id_satellite'}/> {/* TODO CHANGE KEY */}

      <Logo/>
      {/*<MoreInformation/>*/}
      <Contact/>

      <WebGL/>
      {/*<AnnotationOrangeFixedDescriptions/>*/}
    </div>
  );
};

export default Main;
