import React, {useEffect} from 'react';
import {FSMStore, productSheetStore} from 'webgl/stores';
import {useScroll} from '@react-three/drei';

import 'components/Debug/DebugLinks.scss';

/***
 * @deprecated TODO REMOVE DEBUG
 */

const DebugLinks: React.FC = () => {
  const data = useScroll();
  useEffect(() => console.warn('Component %c DebugLinks %c need to be removed in production!', 'font-weight: bold; background-color: #FFD580', 'font-weight: normal'), []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const currentFSMState = FSMStore(state => state.currentFSMState);
  const productSheetStates = productSheetStore(state => state.productSheetStates);
  const setFSMState = FSMStore(state => state.setFSMState);

  return (
    <div className="debug-links-container">
      {Object.entries(productSheetStates)
        .filter(state => typeof state[1] === 'string')
        .map(([key, value]: [string, string]) =>
          <div key={key} className={`debug-links-button${currentFSMState === value ? ' disabled' : ''}`}
            onClick={() => setFSMState(value)}>{key}</div>
        )}

      <div className={`debug-links-help`}>CTRL = vitesse x100<br/>SHIFT = vitesse x3<br/>ALT = vitesse / 10</div>
    </div>

  );
};

export default DebugLinks;