import {Box3, Object3D, Vector3} from 'three';
import {useEffect, useState} from 'react';
import {useFrame} from '@react-three/fiber';

const getCenter = (object: Object3D): Vector3 => {
  // @ts-ignore
  if (object.isMesh) { // get bounding box center
    const boundingBox = new Box3();
    boundingBox.setFromObject(object);
    return boundingBox.getCenter(new Vector3());
  } else { // get pivot position
    return object.getWorldPosition(new Vector3());
  }
};

export const useGetObjectCenter = (object: Object3D, eachFrame: boolean = false): Vector3 => {
  const [position, setPosition] = useState<Vector3>(new Vector3(0, 0, 0));

  useFrame(() => {
    if (eachFrame) {
      setPosition(getCenter(object));
    }
  });

  useEffect(() => {
    setPosition(getCenter(object));

  }, [object]);

  return position;
};