import {logHelper, tLogStyled} from 'utils/Logger';
import {ProductSheet} from 'models/productSheet';

// TODO FAKE DATA

const productSheets_FAKE: ProductSheet.DTO[] = [
  new ProductSheet.DTO('id_satellite', 'key_satellite', 'name_satellite', 'satellite',
    {
      default: 'default',   // display "Racontez votre produit" / webgl very far away
      state1: 'state1', // zoom in planet / zoom in webgl / display contact
      state2: 'state2', // satellite expanded opening

      state3: 'state3', // satellite exploded closing
      state4: 'state4', // satellite exploded opening
      
      details: { // annotations zoom-ins (can't go to this state, only its substates)
        detail1: 'details.detail1', // detail1 opening
        detail2: 'details.detail2', // detail2 opening
        detail3: 'details.detail3', // detail3 opening
      },
      detail1closing: 'detail1closing', // detail1 closing
      detail2closing: 'detail2closing', // detail2 closing
      detail3closing: 'detail3closing', // detail3 closing

      other: {
        moreInformation: 'other.moreInformation',
        contact: 'other.contact'
      }
    })
];

export const getProductSheets_FAKE = async (): Promise<ProductSheet.DTO[]> => {
  tLogStyled('[getProductSheets_FAKE()] using fake data : productSheets_FAKE', logHelper.warning);

  return new Promise<ProductSheet.DTO[]>((resolve) => {
    setTimeout(() => resolve(productSheets_FAKE), 0);
  });
};

export const getProductSheet_FAKE = async (productSheetId?: string): Promise<ProductSheet.DTO | undefined> => {
  tLogStyled('[getProductSheet_FAKE()] using fake data : productSheets_FAKE', logHelper.warning);

  return new Promise<ProductSheet.DTO | undefined>((resolve) => {
    setTimeout(() => resolve(productSheets_FAKE.find(ps => ps.id === productSheetId)), 0);
  });
};