import React, {Suspense, useRef} from 'react';
import {
  AdaptiveEvents
} from '@react-three/drei';
import {sizeClassStore} from 'stores';
import {canvasStore, FSMStore} from 'webgl/stores';
import {FSMStates} from 'webgl/types/FSMStates';
import {ACESFilmicToneMapping} from 'three';
import {Canvas} from '@react-three/fiber';
import RenderController from 'webgl/components/RenderController';
import Lighting from 'webgl/components/Lighting';
import Effects from 'webgl/components/Effects';
import ProductSheet3D from 'webgl/components/ProductSheet/ProductSheet3D';

import './WebGLCanvas.scss';
import CameraControls from 'webgl/components/CameraControls/CameraControls';

type WebGLCanvasProps = {
  allowBlur?: boolean
};

const WebGLCanvas: React.FC<WebGLCanvasProps> = ({allowBlur}) => {
  const webglDivRef = useRef<HTMLDivElement>(null);
  const isHidden = canvasStore(state => state.isHidden);
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  // const productSheetStatesArray = productSheetStore(state => state.productSheetStatesArray);

  console.log("@@@@ currentFSMState : ", currentFSMState)

  return (
    <div
      ref={webglDivRef}
      className={`webgl-canvas ${sizeClass} ${isHidden || currentFSMState === FSMStates.dynamicStates.default ? 'hidden' : 'visible'}`}
    >
      <Canvas
        shadows
        dpr={[1, 1]}
        performance={{min: 0.1}}
        gl={{antialias: true, preserveDrawingBuffer: true/*, alpha: true*/}}
        onCreated={({gl}) => {
          gl.toneMapping = ACESFilmicToneMapping;
        }}
        camera={{position: [0, 1.5, 2], fov: 60}}
        frameloop={'demand'}
      >

        {/*TODO for now controls only if desktop, OrbitControls capture touch events and can't scroll on touch devices...*/}
        <CameraControls/>

        <Suspense fallback={null}>

          <Lighting/>
          {/*<BackgroundSphere/>*/}
          <RenderController/>
          <AdaptiveEvents/>

          {/*<ScrollControls pages={productSheetStatesArray.length} damping={100} distance={2}>*/}
            {/* distance allow bigger distance between states when scrolling */}
            {/*<HandleStateAndScroll states={productSheetStatesArray}/>*/}
            <ProductSheet3D/>

            {/*{productSheetStatesArray.map(({key, value}, i) => // TODO REMOVE DEBUG*/}
            {/*  <SlideBox key={i}*/}
            {/*    visibleFrom={i / productSheetStatesArray.length}*/}
            {/*    visibleDistance={1 / productSheetStatesArray.length}*/}
            {/*    // itemCount={productSheetStatesArray.length}*/}
            {/*    htmlProps={{style: {position: 'absolute', bottom: `1rem`, right: '4rem'}}}>*/}
            {/*    <h1 style={{color:'black',fontFamily: 'jost'}}>{value}</h1>*/}
            {/*  </SlideBox>*/}
            {/*)}*/}

            {/*<TitleDisplay productStates={productSheetStatesArray}/>*/}
          {/*</ScrollControls>*/}

          {/*<gridHelper />*/}
          <Effects/>

        </Suspense>
      </Canvas>
    </div>
  );
};

export default WebGLCanvas;