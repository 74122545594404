import React, {useEffect, useState} from 'react';
import Spinner from 'components/Shared/Spinner';
import {displayWebGLCanvas, updateProductSheet} from 'webgl/services/webglService';
import {FSMStates} from 'webgl/types/FSMStates';
import {cameraStore, FSMStore, productSheetStore} from 'webgl/stores';
import {useParams} from 'react-router-dom';
import {getProductSheet_FAKE} from 'services/getProductSheet__FAKE';
import {DefaultValues} from 'webgl/types/DefaultValues';
import AnnotationOverlay from 'components/Main/ProductSheet/AnnotationOverlay';
import HtmlTitleDisplay from 'components/Main/ProductSheet/HtmlTitleDisplay';
import SpacePlanetBackground from 'components/Main/ProductSheet/SpacePlanetBackground';
import ZoomInOut from 'components/Main/ProductSheet/ZoomInOut';

import './ProductSheetViewer.scss';

type ProductSheetProps = {
  productSheetId?: string
}

const ProductSheetViewer: React.FC<ProductSheetProps> = (props) => {
  const setTargetCameraOffset = cameraStore(state => state.setTargetCameraOffset);
  const productSheetId = useParams().productSheetId || props.productSheetId;
  const [displaySpinner, setDisplaySpinner] = useState<boolean>(false);
  const {isProductSheet3dReady, setProductSheet3dReadiness} = productSheetStore(state => ({
    isProductSheet3dReady: state.isProductSheet3dReady,
    setProductSheet3dReadiness: state.setProductSheet3dReadiness
  }));
  const setFSMState = FSMStore(state => state.setFSMState);

  // just set current product key on mount and remove on unmount
  useEffect(() => {
    setProductSheet3dReadiness(false);

    if (!productSheetId) return;
    getProductSheet_FAKE(productSheetId)
      .then(productSheetDTO => updateProductSheet(productSheetDTO));

    return () => {
      updateProductSheet();
      setFSMState(FSMStates.hidden);
      displayWebGLCanvas(false); // hide webgl canvas when component is unmounted
    }; // unmount
  }, [productSheetId, setFSMState, setProductSheet3dReadiness]);

  // hide spinner and display webgl when product sheet is ready
  useEffect(() => {
    setDisplaySpinner(!isProductSheet3dReady);
    displayWebGLCanvas(isProductSheet3dReady);
    if (isProductSheet3dReady) setFSMState(FSMStates.dynamicStates.default);
  }, [isProductSheet3dReady, setFSMState]);

  useEffect(() => {
    setTargetCameraOffset(DefaultValues.cameraOffset, 600);
  }, [setTargetCameraOffset]);

  return (
    <div className={'product-sheet-container'}>
      <HtmlTitleDisplay/>
      <SpacePlanetBackground/>
      {displaySpinner && <Spinner/>}
      <AnnotationOverlay/>
      <ZoomInOut/>
    </div>
  );
};

export default ProductSheetViewer;