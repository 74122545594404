import {DynamicFSMStates, FSMStates} from 'webgl/types/FSMStates';
import {useEffect} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {ProductSheet} from 'models/productSheet';
import {productSheetStore} from 'webgl/stores';

const ROOT_STATE = 'dynamicStates'; // TODO change this to FSMStates.dynamicStates and get string from it?

const prepareStates = (newStates: DynamicFSMStates, rootState: string) => {
  Object.entries(newStates).map(([key, value]) => {
    if (typeof value === 'object') {
      return prepareStates(value, rootState);
    } else {
      if (value.split('.').shift() !== ROOT_STATE)
        return newStates[key] = `${rootState}.${value}`;
      else
        return newStates[key] = value; // TODO quickfix for hotreload renaming twice
    }
  });
  return newStates;
};

export const useUpdateProductSheetStates = (productSheet?: ProductSheet.DTO): void => {
  const setProductSheetStates = productSheetStore(state => state.setProductSheetStates);

  useEffect(() => {
    FSMStates[ROOT_STATE] = {default: `${ROOT_STATE}.default`}; // overriden if productSheet.states not undefined

    if (productSheet && productSheet.states) {
      FSMStates[ROOT_STATE] = prepareStates(productSheet.states, ROOT_STATE);
    }

    setProductSheetStates(FSMStates[ROOT_STATE]);

    tLogStyled('[useUpdateLineStates]', logHelper.subdued, FSMStates);
  }, [productSheet, setProductSheetStates]);
};