import {AccessTokenDTO} from "../models/accessTokenDTO";
import {CookieUtils} from "../utils/cookieUtils";
import {SingleEntityResponse} from "../models/api/singleEntityResponse";
import ApiService from "./api/apiService";
import {URLs} from "../utils/urls";

export class AuthService extends ApiService {
  public login = async (username: string, password: string): Promise<void> => {
    const response: AccessTokenDTO = await this.post<AccessTokenDTO>(
      URLs.API.LOGIN,
      {
        email: username,
        password: password,
      }
    );

    console.log("got response : ", response);

    CookieUtils.saveToken(response);
    return;
  };

  public requestPasswordReset = async (email: string): Promise<void> => await this.post<void>(URLs.API.RESET_PASSWORD, {login: email});

  public resetPassword = async (token: string, password: string): Promise<void> => await this.patch<void>(`${URLs.API.RESET_PASSWORD}?token=${token}`, {
    newPassword: password,
  });

  public logout = async (): Promise<void> => {
    const response = await this.httpClient.post<void,
      SingleEntityResponse<void>>(URLs.API.LOGOUT);
    CookieUtils.clearToken();
    return response.data;
  };

  public isTokenValid(): boolean {
    return true;
  }
}