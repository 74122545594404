import create from 'zustand';
import {logHelper, tLogStyled} from 'utils/Logger';
import {FSMStates} from 'webgl/types/FSMStates';

const _initialState = FSMStates.notLoaded;

export type FSMStoreProps = {
  currentFSMState?: string;
  currentFSMStateSiblings: string[];
  FSMStateStack: string[];

  stateExists: (state: string, parentState?: object) => boolean;

  setFSMState: (nextState: string) => void;
  setPreviousFSMState: () => void;
  wentBackToPreviousState: boolean;

  isSubStateOf: (parentFSMState: string | Record<string, unknown>) => boolean;
  // getParentStateOf: (state: string) => {[key: string]: string};
  getSiblingStates: (state?: string/*, includeSelf?: boolean*/) => string[];
};

export const FSMStore = create<FSMStoreProps>((set, get) => ({
  currentFSMState: _initialState,
  currentFSMStateSiblings: [],
  FSMStateStack: [_initialState],

  stateExists: (stateToCheck, parentState) => {
    let stateExists = false;
    Object.entries(parentState || FSMStates).forEach(([key, currentState]) => {
      if (typeof currentState === 'object') {
        stateExists ||= get().stateExists(stateToCheck, currentState); // only assign if falsy
      } else {
        stateExists ||= stateToCheck === currentState;
      }
    }); // TODO this adds a dependency to FSMStates...
    return stateExists;
  },

  setFSMState: (newState) => {
    if (!newState || typeof newState === 'object') {
      tLogStyled('[FSMStore] setFSMState error => incorrect state:', logHelper.error, newState); // DEBUG
      return;
    }

    if (!get().stateExists(newState)) {
      tLogStyled('[FSMStore] setFSMState warning => state is valid but doesn\'t exists on FSMStates:', logHelper.warning, newState);
    }

    const stateStack = [...get().FSMStateStack];
    // set({currentFSMState: newState});

    tLogStyled('[FSMStore] setFSMState =>', logHelper.event, newState); // DEBUG

     // OnExit Global Event
    const wentBackToPreviousState = stateStack[stateStack.length - 2] === newState;

    stateStack.push(newState);
    // console.log("new state", newState);
    const siblings = get().getSiblingStates(newState);
    // console.log("siblings:", siblings);
    set({
      currentFSMState: newState,
      FSMStateStack: stateStack,
      currentFSMStateSiblings: siblings,
      wentBackToPreviousState: wentBackToPreviousState
    });
  },

  setPreviousFSMState: () => {
    const stateStack = [...get().FSMStateStack];
    if (stateStack.length > 1) {
      stateStack.pop(); // Remove last state
      get().setFSMState(stateStack.pop() || ''); // Get & remove previous state // TODO: test if double pop() is ok in TS (ok in C#)
    }
  },

  wentBackToPreviousState: false,

  isSubStateOf: (parentFSMState) => {

    // current state is a string like 'guidedVisit.aaa.bbb'
    const {currentFSMState, isSubStateOf} = get();

    if (typeof parentFSMState === 'object') {

      return Object.keys(parentFSMState).some(key => {
        if (typeof parentFSMState[key] === 'object') {
          // @ts-expect-error can't assign undefined to Record because of missing type
          return isSubStateOf(parentFSMState[key]);
        } else {

          return parentFSMState[key] === currentFSMState;
        }
      });

    } else {
      return parentFSMState === currentFSMState;
    }
  },

  getSiblingStates: (state/*, includeSelf = false*/) => {
    if (!state || typeof state === 'object') return [];

    const splitState = state.split('.');
    splitState.pop(); // remove last item
    if (splitState.length >= 1) {
      // @ts-ignore
      const siblings = Object.values(splitState.reduce((acc, cur) => acc[cur], FSMStates) || {})
        .filter(sib => typeof sib === 'string'); //.filter(sib => includeSelf ? true : sib !== state);

      return siblings as string[];
    }
    return [];
  }
}));
