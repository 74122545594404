import React, {useCallback, useState} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';

import './HtmlSlideBox.scss';
import {useRafLoop} from 'react-use';
import {useGetCurrentStateIndex} from 'webgl/hooks';

enum ScrollDirection {
  DOWN = 'scrolling-down',
  // NONE = 'scrolling-none',
  UP = 'scrolling-up'
}

type HtmlSlideBoxProps = {
  htmlProps?: React.HTMLProps<HTMLDivElement>;
  className?: string;
  visibleFrom: number;
  visibleTo?: number;
};

const HtmlSlideBox: React.FC<HtmlSlideBoxProps> = (props) => {
  const {currentIndex} = useGetCurrentStateIndex();

  const [visible, setVisible] = useState<boolean>(false);
  const [direction, setDirection] = useState<ScrollDirection>(ScrollDirection.DOWN);

  useRafLoop((time) => {
    if (props.visibleFrom === undefined) {
      tLogStyled('[HtmlSlideBox] Missing `visibleFrom` property', logHelper.error);
    }

    const distance = props.visibleTo === undefined ? Infinity : props.visibleTo - props.visibleFrom;
    setVisible(currentIndex >= props.visibleFrom && currentIndex <= (props.visibleFrom + distance));

    if (currentIndex > props.visibleFrom) {
      if (direction !== ScrollDirection.DOWN) setDirection(ScrollDirection.DOWN);
    } else if (currentIndex < props.visibleFrom) {
      if (direction !== ScrollDirection.UP) setDirection(ScrollDirection.UP);
    }

    // previousOffset.current = data.offset;
  });

  const getDirection = useCallback(() => {
    // invert the hidden position of exiting item
    if (direction === ScrollDirection.UP && !visible) return ScrollDirection.DOWN;
    if (direction === ScrollDirection.DOWN && visible) return ScrollDirection.UP;
    return direction;
  }, [direction, visible]);

  return (
    <div className={``} style={{pointerEvents: 'none'}}>
      <div {...props.htmlProps}
        className={`${props.className} slide-box-container ${visible ? ' slide-box-visible' : ''} ${getDirection()}`}>
        {visible && props.children} {/* test to prevent pointer-events: auto inside children */}
      </div>
    </div>
  );
};

export default HtmlSlideBox;