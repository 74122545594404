import React, {useCallback, useEffect, useRef, useState} from 'react';
import {sizeClassStore} from 'stores';
import {FSMStore} from 'webgl/stores';
import {useGetCurrentStateIndex} from 'webgl/hooks'; // TODO illustrator...
import './Contact.scss';

import {ReactComponent as Envelope} from 'assets/images/contact_icon_envelope.svg';
import {ReactComponent as CloseCross} from 'assets/images/annotation_croix_fermeture.svg';

const Contact: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const setFSMState = FSMStore(state => state.setFSMState);
  const setPreviousFSMState = FSMStore(state => state.setPreviousFSMState);

  const [overlayVisible, setOverlayVisible] = useState(false);
  const {currentIndex, currentFSMState} = useGetCurrentStateIndex();

  // const email = useRef<string>();
  // const body = useRef<string>();
  // const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
  //   email.current = e.target.value;
  // }, []);
  // const onBodyChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   body.current = e.target.value;
  // }, []);

  const [emailSent, setEmailSent] = useState<boolean>(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const bodyRef = useRef<HTMLTextAreaElement>(null);
  const onEmailSubmit = useCallback((e: React.PointerEvent<HTMLDivElement>) => {
    if (!emailRef.current || !bodyRef.current) return;

    // TODO Contact.tsx => send real email...

    console.log('[Contact] Submitting email:', {email: emailRef.current.value, body: bodyRef.current.value});
    setEmailSent(true);

    // empty fields
    emailRef.current.value = '';
    bodyRef.current.value = '';

    // close overlay
    // setPreviousFSMState(); // close immediately
  }, []);

  const onClose = useCallback(() => {
    if (emailRef.current && bodyRef.current) {
      emailRef.current.value = '';
      bodyRef.current.value = '';
    }
    setEmailSent(false);
    setPreviousFSMState();
  }, [setPreviousFSMState]);

  useEffect(() => setOverlayVisible(currentFSMState === 'contact'), [currentFSMState]);

  if (currentIndex >= 1 || currentFSMState === 'contact') {

    return (
      <>
        <div className={`product-sheet-contact-link ${sizeClass} ${overlayVisible ? '' : 'visible'}`}>
          <div className={`product-sheet-contact-link-text ${sizeClass}`} onClick={() => setFSMState('contact')}>
            <Envelope/> <span>Contact</span>
          </div>
        </div>

        <div
          className={`product-sheet-contact-container ${overlayVisible ? 'visible' : ''}`}> {/* full-page container prevents scrolling */}
          <div
            className={`floating-overlay-box product-sheet-contact-overlay ${sizeClass} ${overlayVisible ? 'visible' : ''}`}>
            <CloseCross className={`product-sheet-contact-overlay-close clickable  ${sizeClass}`}
                        onClick={onClose}/>
            {/*<div className={`product-sheet-contact-overlay-title ${sizeClass}`}>Contact</div>*/}
            {emailSent ?
              <div className={`product-sheet-contact-overlay-content ${sizeClass}`}>
                <span>Votre e-mail a été envoyé !</span>
              </div>
              :
              <div className={`product-sheet-contact-overlay-content ${sizeClass}`}>
                <span>Votre e-mail :</span>
                <span><input ref={emailRef} type={'text'} alt={'email'} /*onChange={onEmailChange}*/ /></span>

                <span>Votre message :</span>
                <span><textarea ref={bodyRef} cols={40} rows={5} /*onChange={onBodyChange}*/ /></span>
                <div className={`product-sheet-contact-overlay-submit clickable ${sizeClass}`}
                     onClick={onEmailSubmit}>Envoyer
                </div>
              </div>}
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default Contact;