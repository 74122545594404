import React from 'react';
import {useParseGLTFAssets, usePreloadGLTFAssets} from 'webgl/hooks';
import {Asset} from 'interfaces';
import {assetStore} from 'webgl/stores';
import WebGLCanvas from 'webgl/components/WebGLCanvas';

const WebGL: React.FC = () => {
  const assets = assetStore(state => state.assets);

  // const percentageLoaded = loadingStore(state => state.percentageLoaded);
  // useEffect(() => tLogStyled('Global loading progress', logHelper.loading, `${percentageLoaded}%`), [percentageLoaded]); // TODO DEBUG

  usePreloadGLTFAssets<Asset[]>(assets); // Load files
  useParseGLTFAssets();


  return (
    <WebGLCanvas/>
  );
};

export default WebGL;