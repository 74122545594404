import {useEffect, useRef} from 'react';
import {logHelper, tLogStyled} from 'utils/Logger';
import {LoopOnce, LoopRepeat} from 'three/src/constants';
import {controlsStore, FSMStore, renderStore} from 'webgl/stores';
import {useAnimations} from '@react-three/drei';
import {AssetEntity} from 'webgl/entities/AssetEntity';
import {useThree} from '@react-three/fiber';
import {AnimationAction} from 'three';

const isCameraAnimated = (action: AnimationAction): boolean => {
  return true;
};
const shouldDisableControls: {current: boolean, previous?: boolean} = {
  current: false,
  previous: undefined
};

export const useHandleAnimations = (equipmentEntity: AssetEntity): void => {
  const {scene} = useThree();
  const isIdle = renderStore(state => state.isIdle);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const {actions} = useAnimations(equipmentEntity.animations, equipmentEntity.scene);
  const setControlsStoreConfig = controlsStore(state => state.setConfig);
  // const {actions} = useAnimations(equipmentEntity.animations, scene);

  useEffect(() => {
    if (currentFSMState) {

      const actionShouldLoop = (state: string) => state.slice(-1) === '*';
      const sanitizeState = (state: string) => state.replace(/[\[|\(](.*?)[\]|\)]/g, '');

      const hasExactMatch = (validStates: string, currentStateToTest: string) => validStates
        .split('&') // split multiple valid states
        .map(validState => actionShouldLoop(validState) ?
          validState.slice(0, -1) : validState) // remove last char if * is added for looping
        .map(validState => sanitizeState(validState)) // remove [xxx] or (xxx) for multiple animation in the same state
        .some(validState => validState === currentStateToTest || validState === 'allStates'); // check if currentState is a valid state

      const isValidRecursive = (validStates: string, currentStateToTest: string): boolean => {
        if (!validStates || !currentStateToTest) return false;

        if (hasExactMatch(validStates, currentStateToTest)) return true;

        else {
          const parent = currentStateToTest.split('.').slice(0, -1); // remove last element
          if (parent.length > 0) return isValidRecursive(validStates, parent.join('.'));
        }
        return false;
      };

      Object.keys(actions)
        .filter(key => !!actions[key])
        .forEach(key => {
          if (isValidRecursive(key, currentFSMState)) {
            if (actionShouldLoop(key)) {
              actions[key]!.loop = LoopRepeat;
              actions[key]!.clampWhenFinished = false;
            } else {
              actions[key]!.loop = LoopOnce;
              actions[key]!.clampWhenFinished = true;
              actions[key]!.time = 0.01;
            }
            actions[key]!.play();
            // actions[key]!.timeScale = 0.25;
            tLogStyled(`[useHandleAnimations] Play action ${key}`, logHelper.start,actions[key]); // DEBUG
          } else {
            actions[key]?.stop();
            tLogStyled(`[useHandleAnimations] Stop action ${key}`, logHelper.stop); // DEBUG
          }
        });
    }
  }, [currentFSMState, actions]);

  useEffect(() => {
    // pause animations when idle
    Object.keys(actions).filter(key => !!actions[key]).forEach(key => {
      actions[key]!.timeScale = isIdle ? 0 : 1;
    });

    // disable CameraControls when camera is animated
    // re-enable when animations are finished
    if (shouldDisableControls.current !== shouldDisableControls.previous) {
      // shouldDisableControls.previous = shouldDisableControls.current;

      setControlsStoreConfig({enabled: shouldDisableControls.current});
    }

  }, [actions, isIdle, setControlsStoreConfig]);
};