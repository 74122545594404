import {logHelper, tLogStyled} from 'utils/Logger';

// TODO FAKE DATE

export type AnnotationOverlayData = {
  activeState: string,
  title?: string,
  iconUri?: string
  description?: string,
  closeState?: string
}

// TODO set Annotation states etc. here instead of glb userData ?
//      only set annotationId in GLB?

const annotationOverlayData__FAKE: AnnotationOverlayData[] = [
  {activeState:'dynamicStates.details.detail1', title:'impact', description:'Le choix de faire vivre une expérience innovante destinée à un public exigeant.', iconUri: './assets/img/icon_01.svg', closeState: 'dynamicStates.detail1closing'},
  {activeState:'dynamicStates.details.detail2', title:'éditable & modulable', description:'Vous avez la main sur le contenu de la page et le modèle 3D peut être modifié.', iconUri: './assets/img/icon_03.svg', closeState: 'dynamicStates.detail2closing'},
  {activeState:'dynamicStates.details.detail3', title:'simple', description:'Les technologies que nous utilisons ne nécessitent aucune installation côté visiteur, pas de plug-in.', iconUri: './assets/img/icon_04.svg', closeState: 'dynamicStates.detail3closing'},
  // {activeState:'dynamicStates.details.detail4', title:'la force de la 3d', description:'La manipulation d\'un objet virtuel favorise la compréhension et la mémorisation.', iconUri: './assets/img/icon_02.svg'},
  // {activeState:'dynamicStates.details.detail5', title:'responsive', description:'L\'expérience est accessible sur desktop, tablette et smartphone.', iconUri: './assets/img/icon_05.svg'},
  // {activeState:'dynamicStates.details.detail6', title:'data', description:'Vous analysez les données de visite pour déterminer quels sont les points forts de votre offre.', iconUri: './assets/img/icon_06.svg'},
];

// TODO SET ANNOTATION DATA TYPE
export const getAnnotationOverlayData_FAKE = async (state: string): Promise<AnnotationOverlayData | undefined> => {
  tLogStyled('[getAnnotationOverlayData_FAKE()] using fake annotation overlay data : annotationOverlayData__FAKE', logHelper.warning);

  return new Promise<AnnotationOverlayData | undefined>((resolve) => {
    setTimeout(() => resolve(annotationOverlayData__FAKE.find(data => data.activeState === state)), 0);
  });
};