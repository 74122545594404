import React, {MutableRefObject, Ref, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Box3, Object3D, Vector3} from 'three';
import {Html, HtmlProps} from '@react-three/drei/web/Html';
import {sizeClassStore} from 'stores';
import {
  useAnnotationForcedAngle,
  useAnnotationHandleOutline,
  useAnnotationPositioning,
  useGetObjectCenter,
  useHandleGotoState
} from 'webgl/hooks';

import './AnnotationBase.scss';
import './AnnotationOrangeHover.scss';
import {useFrame} from '@react-three/fiber';
import {getAnnotationData__FAKE} from 'services/getAnnotationData__FAKE';

type AnnotationProps = {
  object: Object3D
}

const getCenter = (object: Object3D): Vector3 => {
  // @ts-ignore
  if (object.isMesh) { // get bounding box center
    const boundingBox = new Box3();
    boundingBox.setFromObject(object);
    return boundingBox.getCenter(new Vector3());
  } else { // get pivot position
    return object.getWorldPosition(new Vector3());
  }
};

const AnnotationOrangeHover: React.FC<AnnotationProps> = ({object}: AnnotationProps) => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const userData = useMemo(() => object.userData.tags, [object.userData.tags]);
  // TODO getAnnotationData__FAKE(userData.annotationId)
  const isPointerDown = useRef<boolean>(false);
  const [markerTitle, setTitle] = useState<string>(''); // TODO retrieve from backend
  const [isHovered, setHovered] = useState<boolean>(false);


  const [distanceMultiplier] = useState<number | undefined>(userData?.annotationDistanceMultiplier || 0.8); // "top" instead of null by default

  const position = useGetObjectCenter(object, true);
  // const [position, setPosition] = useState<Vector3>(new Vector3());
  const annotationAngle = useAnnotationForcedAngle(userData?.annotationForcePosition);
  const handleGotoState = useHandleGotoState(object);
  const handleOutline = useAnnotationHandleOutline(object);
  const [annotationRef, , overrideCalculatePosition] = useAnnotationPositioning(...annotationAngle, distanceMultiplier);

  const onPointerEnter = useCallback(() => setHovered(true), []);
  const onPointerLeave = useCallback(() => setHovered(false), []);
  const onPointerDown = useCallback(() => isPointerDown.current = true, []);
  const onPointerUp = useCallback(() => {
    if (!isPointerDown.current) return;
    handleGotoState();
    isPointerDown.current = false;
  }, [handleGotoState]);

  useEffect(() => {
    handleOutline(isHovered);
  }, [handleOutline, isHovered]);

  return (
    <Html
      zIndexRange={isHovered ? [502, 501] : [500, 100]} // need component to update when changing zIndexRange
      style={{pointerEvents: 'none'}}
      position={[position.x, position.y, position.z]}
      // position={getCenter(object)}
      calculatePosition={overrideCalculatePosition}
      name={`AnnotationOrangeHover Html ${[object.name]}`}
    >
      <div className="annotation-container">

        <svg className={`annotation-circle orangeHover ${sizeClass} `}
          onPointerDown={onPointerDown} // was only applied to annotationRef, reason?
          onPointerUp={onPointerUp} // was only applied to annotationRef, reason?
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
        >
          <circle id="backCircle"/>
          <circle id="frontCircle"/>
        </svg>

        <div ref={annotationRef}
          className={`annotation orangeHover ${sizeClass} ${isHovered && markerTitle ? 'visible' : ''}`}>
          <div className={`title ${sizeClass}`}>{markerTitle}</div>
        </div>

      </div>
    </Html>
  );
};

export default AnnotationOrangeHover;
