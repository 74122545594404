import {unstable_batchedUpdates} from 'react-dom'; // or 'react-native'
import {canvasStore, renderStore, assetStore, productSheetStore} from 'webgl/stores';
import {logHelper, tLogStyled} from 'utils/Logger';
import {Asset} from 'interfaces';
import {ProductSheet} from 'models/productSheet';

// see https://docs.pmnd.rs/zustand/recipes#calling-actions-outside-a-react-event-handler
export const displayWebGLCanvas = (value: boolean) => unstable_batchedUpdates(() => {
  tLogStyled(`[webglService] displayWebGLCanvas(${value})`, logHelper.service, `WebGL Canvas is ${value ? 'visible' : 'hidden'}`); // DEBUG
  canvasStore.getState().setIsHidden(!value);
  renderStore.getState().setPauseRender(!value);
});

export const updateAssetList = (list: Asset[]): Asset[] => {
  unstable_batchedUpdates(() => {
    tLogStyled(`[webglService] updateAssetList()`, logHelper.service, list); // DEBUG
    assetStore.getState().setAssets(list); // watched by usePreloadAssets => will start loading GLTFs
  });
  return list;
};

export const updateProductSheet = (productSheet?: ProductSheet.DTO) => unstable_batchedUpdates(() => {
  tLogStyled(`[webglService] updateProductSheet()`, logHelper.service, productSheet); // DEBUG
  productSheetStore.getState().setProductSheet(productSheet);
});
