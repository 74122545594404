import React from 'react';
import {AssetEntity} from 'webgl/entities/AssetEntity';
import {useHandleAnimations} from 'webgl/hooks/useHandleAnimations';
// import {useHandleChainedAnimations} from 'webgl/hooks';
// import {productSheetStore} from 'webgl/stores';

type ProductProps = {
  productEntity: AssetEntity;
}

const Product: React.FC<ProductProps> = ({productEntity}) => {
  // const productSheetStatesArray = productSheetStore(state => state.productSheetStatesArray);
  // const productSheetSubStatesArray = productSheetStore(state => state.productSheetSubStatesArray);

  // useHandleChainedAnimations(productEntity, productSheetSubStatesArray); // play animations
  useHandleAnimations(productEntity);

  return (
    <primitive object={productEntity.scene}/>
  );
};

export default Product;