import * as React from 'react';
import {useCallback, useMemo} from 'react';
import {FSMStore} from 'webgl/stores';

import './ZoomInOut.scss';
import {ReactComponent as ZoomIn} from 'assets/images/zoom_in.svg';
import {ReactComponent as ZoomOut} from 'assets/images/zoom_out.svg';
import {sizeClassStore} from 'stores';
import {FSMStates} from 'webgl/types/FSMStates';

enum SatelliteState {
  // CLOSED,
  DEPLOYED,
  EXPLODED
}

const ZoomInOut: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const setFSMState = FSMStore(state => state.setFSMState);
  // const {currentIndex, getStateFromIndex} = useGetCurrentStateIndex();

  const currentSatelliteState = useMemo<SatelliteState>(() => {
    // console.log("@@@ currentIndex:", currentIndex);
    // if (currentIndex <= 1) return SatelliteState.CLOSED;
    // if (currentIndex === 2) return SatelliteState.DEPLOYED;
    // /*if (currentIndex >= 5) */
    // return SatelliteState.EXPLODED;

    return currentFSMState === FSMStates.dynamicStates.state4 ? SatelliteState.EXPLODED : SatelliteState.DEPLOYED;

  }, [currentFSMState]);

  // const zoomIn = useCallback(() => setFSMState(getStateFromIndex(currentIndex + 1)), [currentIndex, getStateFromIndex, setFSMState]);
  // const zoomOut = useCallback(() => setFSMState(getStateFromIndex(Math.min(currentIndex - 1, 5))), [currentIndex, getStateFromIndex, setFSMState]);
  const zoomIn = useCallback(() => setFSMState(FSMStates.dynamicStates.state4), [setFSMState]);
  const zoomOut = useCallback(() => setFSMState(FSMStates.dynamicStates.state3), [setFSMState]);

  // if (currentIndex < 2) return null; // hide if annotations are not visible (below state3)
  if (currentFSMState !== FSMStates.dynamicStates.state2 &&
    currentFSMState !== FSMStates.dynamicStates.state3 &&
    currentFSMState !== FSMStates.dynamicStates.state4 &&
    currentFSMState !== FSMStates.dynamicStates.detail1closing &&
    currentFSMState !== FSMStates.dynamicStates.detail2closing &&
    currentFSMState !== FSMStates.dynamicStates.detail3closing
  ) return null; // hide if annotations are not visible (below state3)

  return (
    <div className={`zoom-in-out ${sizeClass}`}>

      <ZoomIn
        className={`zoom-in-out zoom-in${currentSatelliteState === SatelliteState.EXPLODED ? ' zoom-in-out-inactive' : ' zoom-in-out-active'}`}
        onClick={zoomIn}/>

      <ZoomOut
        // className={`zoom-in-out zoom-out${[SatelliteState.CLOSED, SatelliteState.DEPLOYED].includes(currentSatelliteState) ? ' zoom-in-out-inactive' : ' zoom-in-out-active'}`}
        className={`zoom-in-out zoom-out${currentSatelliteState === SatelliteState.DEPLOYED ? ' zoom-in-out-inactive' : ' zoom-in-out-active'}`}
        onClick={zoomOut}/>

    </div>
  );
};

export default ZoomInOut;