import create from 'zustand';

export type ContactStoreProps = {
  contactOverlayOpened: boolean;
  setContactOverlayOpened: (value: boolean) => void;
}

export const contactStore = create<ContactStoreProps>((set) => ({
  contactOverlayOpened: false,
  setContactOverlayOpened: (value) => set({contactOverlayOpened: value}),
}));